var $ = jQuery.noConflict();

$(window).on("load", function () {
    let method = "front";
    if ($("body").hasClass("wp-admin")) method = "wp-admin";

    var initializeBlock = function ($block) {
        if (
            $(window).width() > 992 &&
            $(".block-related-news__posts-holder").hasClass(
                "swiper-initialized"
            )
        ) {
            // document.newsSlider.destroy();
        } else {
            $(".block-related-news .swiper").each(function () {
                var blockId = $(this).closest(".block-related-news").attr("id");

                var swiperInit = new Swiper(this, {
                    autoHeight: false,
                    loop: false,
                    slideClass: "swiper-slide",
                    spaceBetween: 30,
                    breakpoints: {
                        1: {
                            slidesPerView: 1,
                        },
                        992: {
                            slidesPerView: 2,
                        },
                        1350: {
                            slidesPerView: 3,
                        },
                    },
                    navigation: {
                        prevEl: "#" + blockId + " .btn-circle--prev",
                        nextEl: "#" + blockId + " .btn-circle--next",
                    },
                });

                $(this)
                    .closest(".block-related-news__posts-holder")
                    .addClass("load");
            });
        }
    };

    window.addEventListener("resize", function () {
        initializeBlock($(this));
    });

    window.onload = (function () {
        initializeBlock($(this));
    })();

    if (window.acf) {
        window.acf.addAction(
            "render_block_preview/type=related-news",
            initializeBlock
        );
    }
});
